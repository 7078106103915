<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">Edit Landlord Details </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="landlord-form">
          <v-text-field
            label="Landlord Name"
            v-model="fields.landlord_name"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('landlord_name')"
            :error-messages="errors['landlord_name']"
          ></v-text-field>

          <v-text-field
            label="Landlord Email"
            v-model="fields.landlord_email"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('landlord_email')"
            :error-messages="errors['landlord_email']"
          ></v-text-field>

          <v-text-field
            label="Landlord Email 2"
            v-model="fields.landlord_email_2"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('landlord_email_2')"
            :error-messages="errors['landlord_email_2']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="landlord-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      property: {},
      fields: {
        landlord_email: null,
        landlord_name: null,
        landlord_email_2: null,
      },
      errors: {},
    };
  },

  computed: {},

  methods: {
    openForm(property = null) {
      if (property !== null) {
        this.property = property;
        this.isEditing = true;

        this.fields.landlord_email = property.landlord_email;
        this.fields.landlord_name = property.landlord_name;
        this.fields.landlord_email_2 = property.landlord_email_2;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      this.fields.form_title = "landlord";

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.propertyId = this.property.id;
      }

      this.$store
        .dispatch("sbpm/propertiesStore/saveProperty", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.property = {};
      this.fields = {
        landlord_name: null,
        landlord_email: null,
        landlord_email_2: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
